import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useStripe } from '@stripe/react-stripe-js'
import { useMutation, useQuery } from '@apollo/client'
import {
  order, user,
  equipment as graphqlEquipment,
  golfClubModel as graphqlGolfClubModel,
  golfClubType as graphqlGolfClubType
} from '@graphql'
import {
  AddressCard,
  BuyingOwnGolfClubModalState,
  GolfClub,
  GolfClubModel,
  GolfClubType,
  GolfEquipment,
  GolfEquipmentModel,
  GolfEquipmentType,
  MutationResponse,
  PaymentCard,
  ProductPageItemType,
  ResponseType
} from '@types'
import { useAlert } from 'react-alert'
import { useLocationWithState, useModal, useWindowSize } from '@hooks'

import { CheckoutLayout } from '@layouts'

import PurseIcon from '@images/purse.svg'
import BackArrow from '@images/back-arrow.svg'

interface CheckoutBuyNowProps {
  id: string
}

type CreateOrderMutationResponse = ResponseType<MutationResponse & { result: { stripeSessionId: string; id: string } }>

const CheckoutBuyNow: React.FC<CheckoutBuyNowProps> = ({ id }) => {
  const stripe = useStripe()
  const alert = useAlert()

  const [selectedGolfClub, setSelectedGolfClub] = useState<GolfClub | GolfEquipment | undefined>()
  const { openModal } = useModal<{ ids: string[]; callback: () => void }>('DeleteSimilarGolfClubs')
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  const { data: userData } = useQuery(user.FetchCurrentUser, { fetchPolicy: 'cache-only' })

  const { pathname, state } = useLocationWithState<{ type: string }>()
  const isGolfClubPage = state.type === "GolfClubModel"

  /**
    * * isGolfClubPage === true - fetch golfClub Types
    * * isGolfClubPage === false - fetch golfEquipment Types
  */
  const golfItemTypeQuery = isGolfClubPage ? graphqlGolfClubType.FetchGolfClubType : graphqlEquipment.FetchGolfEquipmentType
  const { data: golfClubTypeData, loading: golfClubTypeLoading } = useQuery<
    ResponseType<GolfClubType | GolfEquipmentType>
  >(golfItemTypeQuery, { variables: { id }, fetchPolicy: 'network-only' })
  const [createOrder, { data: orderData, loading: orderLoading }] = useMutation<CreateOrderMutationResponse>(
    order.CreateOrder
  )
  const { openModal: openBuyingOwnGolfClubModal } = useModal<BuyingOwnGolfClubModalState>('BuyingOwnGolfClubModal')
  const currentUser = userData && userData.res
  const currentUserId = currentUser && currentUser.id
  const selectedGolfClubOwnerId = selectedGolfClub && selectedGolfClub.owner && selectedGolfClub.owner.id

  const golfClubType = golfClubTypeData?.res
  let golfClubsOnSale: GolfClub[] | GolfEquipment[]
  let golfModel: GolfEquipmentModel | GolfClubModel

  if (golfClubType && 'golfClubsOnSale' in golfClubType) {
    golfClubsOnSale = golfClubType.golfClubsOnSale
    golfModel = golfClubType.golfClubModel
  } else if (golfClubType && 'golfEquipmentsOnSale' in golfClubType) {
    golfClubsOnSale = golfClubType.golfEquipmentsOnSale
    golfModel = golfClubType.golfEquipmentModel
  }

  const usersSimilarPurchaseRequests = (golfClubType?.purchaseRequests || []).filter(purchaseRequest => {
    const isCurrentUsersPurchaseRequest = currentUserId === purchaseRequest?.user?.id
    return purchaseRequest.status === 'OPEN' && isCurrentUsersPurchaseRequest
  })



  const pathnameIndex = pathname.indexOf('buy-now')
  const checkoutSuccessValue = pathname.substr(pathnameIndex)

  useEffect(() => {
    const order = orderData?.res?.result
    const stripeSessionId = order?.stripeSessionId

    if (order) {
      if (stripeSessionId) {
        stripe?.redirectToCheckout({ sessionId: stripeSessionId })
      }

      if (order?.id) {
        navigate(`/checkout-success/?orderId=${order.id}`, { state: { checkoutSuccessValue: checkoutSuccessValue } })
      }
    }
  }, [orderData])

  useEffect(() => {
    if (golfClubTypeData && golfClubTypeData.res && golfClubsOnSale?.length) {
      setSelectedGolfClub(golfClubsOnSale[0])
    }
  }, [golfClubTypeData])

  const handleCreateOrder = async (addressCard: AddressCard, userCard: PaymentCard, promoCodeId?: string) => {
    if (selectedGolfClub) {
      const addressCardId = addressCard.id
      const paymentCardId = userCard.id
      const golfClubId = selectedGolfClub.id
      let payload

      if (golfModel.__typename === 'GolfClubModel')
        payload = {
          addressCardId,
          paymentCardId,
          golfClubId,
          promoCodeId
        }
      else if (golfModel.__typename === 'GolfEquipmentModel')
        payload = {
          addressCardId,
          paymentCardId,
          golfEquipmentId: selectedGolfClub.id,
          promoCodeId
        }

      await createOrder({
        variables: { order: payload },
        refetchQueries: [{ query: graphqlGolfClubModel.FetchGolfClubModel, variables: { id: golfModel.id || '' } }]
      }).then(response => {
        const golfClubModelSlug = golfModel.slug
        const status = response.data?.res.successful

        if (status === false) {
          const errorDetail = response.data?.res?.messages[0].message
          alert.show(`Error! ${errorDetail}`, {
            type: 'error'
          })


          const isGolfClubPage = golfModel.__typename === 'GolfClubModel'
          const itemType: ProductPageItemType = isGolfClubPage ? 'GolfClubModel' : 'GolfEquipmentModel'

          navigate(`/product/${golfClubModelSlug}/${itemType}`)
        }
      })
    }
  }

  const handleConfirm = async (
    addressCard?: AddressCard,
    userCard?: PaymentCard,
    hasBeenModalOpened?: boolean,
    promoCodeId?: string
  ) => {
    if (!hasBeenModalOpened && usersSimilarPurchaseRequests.length) {
      const usersSimilarPurchaseRequestIds = usersSimilarPurchaseRequests.map(purchaseRequest => purchaseRequest.id)
      openModal({
        ids: usersSimilarPurchaseRequestIds || [],
        callback: () => handleConfirm(addressCard, userCard, true)
      })
      return
    }

    if (currentUserId === selectedGolfClubOwnerId) {
      return openBuyingOwnGolfClubModal({ slug: golfModel.slug, isSell: false })
    } else {
      await handleCreateOrder(addressCard!, userCard!, promoCodeId!)
    }
  }

  if (!golfClubType) return null
  return (
    <CheckoutLayout
      golfTypeData={golfClubTypeData}
      golfTypeLoading={golfClubTypeLoading}
      handleConfirm={handleConfirm}
      price={selectedGolfClub?.price}
      title={
        <strong className="checkout-payment-info-title subtitle1">
          {isMobileRender && <BackArrow onClick={() => navigate(-1)} />}
          <PurseIcon />
          Buy Now
        </strong>
      }
      mutationLoading={orderLoading}
    >
      <></>
    </CheckoutLayout>
  )
}

export default CheckoutBuyNow
